import React from 'react';
import injectSheet from 'react-jss';

import Translate from '../../../components/Translate';
import Layout from '../../../components/layout';
import IndexSmartScaleTabs from '../../../components/indexSmartScaleTabs';
import wifiIcon from '../../../data/images/SVG-files/icon-wifi-dark.svg';
import bluetoothIcon from '../../../data/images/SVG-files/icon-bluetooth.svg';
import antIcon from '../../../data/images/SVG-files/icon-ant.svg';
import batteryStatusIcon from '../../../data/images/SVG-files/icon-battery-status.svg';
import bmiIcon from '../../../data/images/SVG-files/icon-scale-bmi.svg';
import fatIcon from '../../../data/images/SVG-files/icon-scale-body-fat.svg';
import waterIcon from '../../../data/images/SVG-files/icon-scale-body-water.svg';
import boneMassIcon from '../../../data/images/SVG-files/icon-scale-bone-mass.svg';
import kgIcon from '../../../data/images/SVG-files/icon-scale-kg.svg';
import muscleIcon from '../../../data/images/SVG-files/icon-scale-muscle-mass.svg';
import poundIcon from '../../../data/images/SVG-files/icon-scale-pounds.svg';
import stoneIcon from '../../../data/images/SVG-files/icon-scale-stone.svg';
import wrenchIcon from '../../../data/images/SVG-files/icon-wrench.svg';
import themeColors from '../../../styles/theme';

const styles = {
    wrapper: {
        backgroundColor: '#fff'
    },
    content: {
        width: '80%',
        paddingLeft: '10%',
        paddingTop: '80px',
        '& h3': {
            lineHeight: '1.25',
            marginBottom: '20px',
            fontSize: '26px',
            fontWeight: '300'
        },
        '& a': {
            color: themeColors.primaryTextLinks,
            outline: 'none'
        }
    },
    section: {
        display: 'inline-flex',
        marginBottom: '40px',
        '& h4': {
            marginBlockEnd: '0',
            marginBlockStart: '0',
            fontSize: '20px',
            lineHeight: '1.15',
            fontWeight: '300'
        },
        '& li': {
            marginBottom: '20px'
        }
    },
    text: {
        marginRight: '50px',
        width: '70%'
    },
    faq: {
        display: 'inline-block',
        width: '80%',
        borderBottom: '1px solid rgba(199,199,199,0.3)'
    },
    manual: {
        verticalAlign: 'top',
        display: 'inline-block'
    },
    icon: {
        width: '35px',
        display: 'inline-block',
        height: '35px',
        marginLeft: '15px'
    },
    iconSmall: {
        width: '15px',
        height: '15px'
    },
    wifi: {
        composes: '$icon',
        background: `url(${wifiIcon}) no-repeat`
    },
    bluetooth: {
        composes: '$icon',
        background: `url(${bluetoothIcon}) no-repeat`
    },
    ant: {
        composes: '$icon',
        background: `url(${antIcon}) no-repeat`
    },
    batteryStatus: {
        composes: '$icon',
        background: `url(${batteryStatusIcon}) no-repeat`
    },
    bmi: {
        composes: '$icon',
        background: `url(${bmiIcon}) no-repeat`
    },
    bodyFat: {
        composes: '$icon',
        background: `url(${fatIcon}) no-repeat`
    },
    bodyWater: {
        composes: '$icon',
        background: `url(${waterIcon}) no-repeat`
    },
    boneMass: {
        composes: '$icon',
        background: `url(${boneMassIcon}) no-repeat`
    },
    kilo: {
        composes: '$icon',
        background: `url(${kgIcon}) no-repeat`
    },
    muscle: {
        composes: '$icon',
        background: `url(${muscleIcon}) no-repeat`
    },
    pounds: {
        composes: '$icon',
        background: `url(${poundIcon}) no-repeat`
    },
    stone: {
        composes: '$icon',
        background: `url(${stoneIcon}) no-repeat`
    },
    '@media only screen and (max-width: 767px)': {
        content: {
            width: '75%',
            marginLeft: '0',
            paddingLeft: '15%'
        },
        text: {
            marginRight: '50px',
            width: '100%'
        },
        faq: {
            width: '100%'
        }
    }
};

class Help extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Layout backgroundColor="#fafafa">
                <div>
                    <IndexSmartScaleTabs selectedTab="faq" />
                    <div className={classes.wrapper}>
                        <div className={classes.content}>
                            <div className={classes.faq}>
                                <h3>{<Translate ns="prelogin_pages" content="frequently_asked_questions" />}</h3>

                                <div className={classes.section}>
                                    <div className={classes.text}>
                                        <h4>
                                            {<Translate ns="prelogin_pages" content="find_serial_number_question" />}
                                        </h4>
                                        <p>
                                            {
                                                <Translate
                                                    ns="prelogin_pages"
                                                    content="find_serial_number_question_answer"
                                                />
                                            }
                                        </p>
                                    </div>
                                </div>

                                <div className={classes.section}>
                                    <div className={classes.text}>
                                        <h4>
                                            {<Translate ns="prelogin_pages" content="how_do_i_change_wifi_question" />}
                                        </h4>
                                        <p>
                                            {
                                                <Translate
                                                    ns="prelogin_pages"
                                                    content="how_do_i_change_wifi_instruction1"
                                                />
                                            }
                                        </p>
                                        <br />
                                        <p>
                                            {
                                                <Translate
                                                    ns="prelogin_pages"
                                                    content="how_do_i_change_wifi_instruction2"
                                                    params={{
                                                        0: (
                                                            <Translate ns="prelogin_pages">
                                                                {(t) => (
                                                                    <img
                                                                        src={wrenchIcon}
                                                                        className={classes.iconSmall}
                                                                        alt={t('wrench')}
                                                                    />
                                                                )}
                                                            </Translate>
                                                        ),
                                                        1: (
                                                            <Translate ns="prelogin_pages">
                                                                {(t) => (
                                                                    <img
                                                                        src={bluetoothIcon}
                                                                        className={classes.iconSmall}
                                                                        alt={t('bluetooth')}
                                                                    />
                                                                )}
                                                            </Translate>
                                                        ),
                                                        2: (
                                                            <Translate ns="prelogin_pages">
                                                                {(t) => (
                                                                    <img
                                                                        src={antIcon}
                                                                        className={classes.iconSmall}
                                                                        alt={t('ant_status')}
                                                                    />
                                                                )}
                                                            </Translate>
                                                        ),
                                                        3: (
                                                            <Translate ns="activity-sync">
                                                                {(t) => (
                                                                    <img
                                                                        src={wifiIcon}
                                                                        className={classes.iconSmall}
                                                                        alt={t('how.to.wifi.title')}
                                                                    />
                                                                )}
                                                            </Translate>
                                                        )
                                                    }}
                                                />
                                            }
                                        </p>
                                        <br />
                                        <p>
                                            {
                                                <Translate
                                                    ns="prelogin_pages"
                                                    content="how_do_i_change_wifi_instruction3"
                                                />
                                            }
                                        </p>
                                    </div>
                                </div>

                                <div className={classes.section}>
                                    <div className={classes.text}>
                                        <h4>
                                            {<Translate ns="prelogin_pages" content="what_are_the_icons_on_scale" />}
                                        </h4>
                                        <ul>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="wifi_signal_status" />}
                                                <span className={classes.wifi} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="bluetooth_status" />}
                                                <span className={classes.bluetooth} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="ant_status" />}
                                                <span className={classes.ant} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="battery_status" />}
                                                <span className={classes.batteryStatus} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="body_mass_index" />}
                                                <span className={classes.bmi} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="body_fat_percentage" />}
                                                <span className={classes.bodyFat} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="body_water_percentage" />}
                                                <span className={classes.bodyWater} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="skeletal_muscle_mass" />}
                                                <span className={classes.muscle} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="bone_mass" />}
                                                <span className={classes.boneMass} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="stone" />}
                                                <span className={classes.stone} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="pounds" />}
                                                <span className={classes.pounds} />
                                            </li>
                                            <li>
                                                {<Translate ns="prelogin_pages" content="kilograms" />}
                                                <span className={classes.kilo} />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.manual}>
                                <h3>{<Translate ns="prelogin_pages" content="manuals_label" />}</h3>
                                <ul>
                                    <li>
                                        <a href="https://www8.garmin.com/manuals/webhelp/indexscale/EN-US/GUID-B743541F-B3B5-484A-997D-AD54117FFE9B-homepage.html">
                                            {<Translate ns="prelogin_pages" content="owner_manual" />}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(Help);
